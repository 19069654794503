import clsx from 'clsx';
import { PropsWithChildren, ReactElement } from 'react';

interface Props {
    className?: string;
}

export default function Content(props: PropsWithChildren<Props>): ReactElement {
    return (
        <div className={clsx('bg-white p-4 rounded-md w-full', props.className)}>
            {props.children}
        </div>
    );
}
